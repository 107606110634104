<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :icon="icon"
    :errors="errors"
    :is-active="isActive"
    :is-loading="isLoading || isInternalLoading"
    :disable-disabled-opacity="disableDisabledOpacity"
    :feedback="feedback"
    :is-disabled="isDisabled"
    :has-value="isNumber(inputValue)"
    @click="() => input?.focus()"
    @keydown.enter="() => input?.focus()"
    @focus="
      () => {
        if (activateOnFocus) {
          input?.focus();
        }
      }
    "
  >
    <div class="input-text" v-class-mod:input-text="{ isDisabled }">
      <span class="input-text__input-wrapper">
        <input
          :placeholder="isDisabled ? '-' : placeholder"
          :pattern="`\\d.`"
          :disabled="isDisabled"
          :tabindex="!activateOnFocus ? -1 : 0"
          :autofocus="autofocus"
          inputmode="numeric"
          class="input-text__input"
          type="number"
          @focus="() => (isActive = true)"
          @blur="
            () => {
              updateValue(inputValue);
              isActive = false;
            }
          "
          @keydown.enter.exact.stop="
            () => {
              input?.blur();
              emit('enter');
            }
          "
          @keydown.esc="
            () => {
              input?.blur();
              emit('escape');
            }
          "
          @keydown.up="
            (e) => {
              if (isNumber(rawValue)) {
                rawValue++;
                e.preventDefault();
              }
            }
          "
          @keydown.down="
            (e) => {
              if (isNumber(rawValue)) {
                rawValue--;
                e.preventDefault();
              }
            }
          "
          ref="input"
          v-model="inputValue"
        />
      </span>

      <span v-if="unit && (isNumber(value) || !isDisabled)" class="input-text__unit">{{ unit }}</span>

      <icon-toggle-button
        v-if="canClear && !isDisabled && isNumber(rawValue)"
        :tooltip="{ title: translations.inputs.clear }"
        :action="
          (e) => {
            e.stopPropagation();
            rawValue = undefined;
            updateValue();
            input?.focus();
          }
        "
        button-size="medium"
        icon="close"
        tabindex="0"
        class="input-text__clear"
      />
    </div>
  </base-label>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref, watch, watchEffect } from "vue";

import { settings } from "@horizon56/bootstrap";
import { delayFn, isNumber } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";
import { FeedbackReturnType } from "@/components/inputs/input-feedback.vue";

const IconToggleButton = defineAsyncComponent(() => import("@/components/buttons/icon-toggle-button.vue"));

const props = withDefaults(
  defineProps<
    PublicProps & {
      value?: number;
      placeholder?: string;
      canClear?: boolean;
      unit?: string;
      isDisabled?: boolean;
      autofocus?: boolean;
      activateOnFocus?: boolean;
      update?: (v?: number) => FeedbackReturnType;
    }
  >(),
  { blurOnEnter: true, activateOnFocus: true },
);

const emit = defineEmits<{
  (e: "update", text?: number): void;
  (e: "enter"): void;
  (e: "escape"): void;
  (e: "is-active", active: boolean): void;
}>();

const format = (nr: number | undefined) => (isNumber(nr) ? +nr.toFixed(settings.value.numberOfDecimalsDiplayed) : nr);

const isActive = ref(false);
const input = ref<HTMLInputElement>();
const rawValue = ref(props.value);
const formatedValue = ref(format(rawValue.value));
const inputValue = ref(formatedValue.value);

const isInternalLoading = ref(false);
const feedback = ref<PublicProps["feedback"]>();

const updateValue = async (u?: number) => {
  emit("update", u);
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    const result = await props.update(u);
    if (result && "status" in result) {
      feedback.value = {
        ...result,
        callback: () => {
          feedback.value = undefined;
          result.callback?.();
        },
      };
    }
    isInternalLoading.value = false;
  }
};

const focus = async () => {
  if (!input.value) {
    await delayFn(() => !!input.value);
  }
  input?.value?.focus();
  input?.value?.select();
};

watch(
  () => isActive.value,
  (is) => {
    emit("is-active", is);
    inputValue.value = is ? rawValue.value : formatedValue.value;
  },
);

watch(
  () => props.autofocus,
  (has) => {
    if (has) {
      window.requestAnimationFrame(focus);
    }
  },
);

watchEffect(() => {
  formatedValue.value = isNumber(props.value)
    ? +props.value.toFixed(settings.value.numberOfDecimalsDiplayed)
    : props.value;
  rawValue.value = props.value;
  inputValue.value = isActive.value ? rawValue.value : formatedValue.value;
});

onMounted(() => {
  if (props.autofocus) {
    window.requestAnimationFrame(focus);
  }
});
</script>

<style lang="scss" scoped>
@import "./mixins";
.input-text {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  &__input {
    @include input-reset;
    width: 100%;
    line-height: 1;
    text-align: right;
    min-width: var(--input-text-width);
    font-size: var(--app-font-size-base);
    &-wrapper {
      align-items: flex-end;
      display: flex;
      flex-flow: column;
      overflow: hidden;
      padding-left: var(--input-spacing, 0px);
      width: var(--input-text-width-formatted, 100%);
    }
  }
  &--isDisabled #{&}__input {
    pointer-events: none;
  }
  &__unit {
    white-space: nowrap;
  }
}
</style>
